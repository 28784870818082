"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var LZString = require("./lz-string");
var PROTOCOL_VERSION = "2.0";
var WatchRTCSocket = /** @class */ (function () {
    function WatchRTCSocket(options) {
        this.connection = null;
        this.wasConnected = false;
        this.buffer = [];
        this.sendInterval = 1;
        this.onClose = function () { };
        this.debug = false;
        this.dataCollection = true;
        if (WatchRTCSocket._instance) {
            console.info.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["WatchRTCSocket instance already created"]));
        }
        else {
            WatchRTCSocket._instance = this;
            this.debug = !!options.debug;
        }
    }
    WatchRTCSocket.prototype.connect = function (url, onData, onError) {
        if (WatchRTCSocket._instance.connection) {
            WatchRTCSocket._instance.connection.close();
        }
        var _this = WatchRTCSocket._instance;
        WatchRTCSocket._instance.connection = new WebSocket(url, PROTOCOL_VERSION);
        WatchRTCSocket._instance.connection.onopen = function (_e) { };
        WatchRTCSocket._instance.connection.onclose = function (_e) { };
        WatchRTCSocket._instance.connection.onmessage = function (e) {
            var _a;
            try {
                var data = JSON.parse(e.data);
                if (data.error) {
                    (_a = _this === null || _this === void 0 ? void 0 : _this.connection) === null || _a === void 0 ? void 0 : _a.close();
                    _this.connection = null;
                    console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["\n" + data.error]));
                    onError(data.error);
                }
                else {
                    if (data.sendInterval) {
                        WatchRTCSocket._instance.sendInterval = data.sendInterval;
                    }
                    onData(data);
                    WatchRTCSocket._instance.wasConnected = true;
                }
            }
            catch (err) {
                console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), [{ err: err.stack }]));
                onError(err.message);
            }
        };
        WatchRTCSocket._instance.connection.onerror = function (e) {
            console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["\n", e]));
            onError(e);
        };
    };
    WatchRTCSocket.prototype.trace = function () {
        var data = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            data[_i] = arguments[_i];
        }
        var args = Array.prototype.slice.call(data);
        args.push(Date.now());
        if (args[1] instanceof RTCPeerConnection) {
            args[1] = args[1].__rtcStatsId;
        }
        if (!WatchRTCSocket._instance.dataCollection) {
            return;
        }
        if (!WatchRTCSocket._instance.connection) {
            if (WatchRTCSocket._instance.buffer.length > 1000) {
                return;
            }
            WatchRTCSocket._instance.buffer.push(args);
            return;
        }
        if (WatchRTCSocket._instance.connection.readyState === WebSocket.OPEN) {
            WatchRTCSocket._instance.buffer.push(args);
            if (WatchRTCSocket._instance.buffer.length >= WatchRTCSocket._instance.sendInterval) {
                var lines = JSON.stringify(WatchRTCSocket._instance.buffer);
                var compressedMessage = LZString.compressToEncodedURIComponent(lines);
                if (WatchRTCSocket._instance.debug) {
                    console.log.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["lines: " + lines.length]));
                    console.log.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["compressedMessage: " + compressedMessage.length]));
                }
                WatchRTCSocket._instance.buffer = [];
                WatchRTCSocket._instance.connection.send(compressedMessage);
            }
        }
    };
    WatchRTCSocket.prototype.close = function () {
        WatchRTCSocket._instance.buffer = [];
        if (WatchRTCSocket._instance.connection) {
            WatchRTCSocket._instance.connection.close();
            WatchRTCSocket._instance.onClose();
            WatchRTCSocket._instance.connection = null;
        }
    };
    WatchRTCSocket.prototype.disableDataCollection = function () {
        if (WatchRTCSocket._instance.debug) {
            console.log.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["Data collection disabled."]));
        }
        WatchRTCSocket._instance.dataCollection = false;
    };
    WatchRTCSocket.prototype.enableDataCollection = function () {
        if (WatchRTCSocket._instance.debug) {
            console.log.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["Data collection enabled."]));
        }
        WatchRTCSocket._instance.dataCollection = true;
    };
    WatchRTCSocket.prototype.toggleDebug = function (debug) {
        WatchRTCSocket._instance.debug = debug;
    };
    return WatchRTCSocket;
}());
exports.default = WatchRTCSocket;
