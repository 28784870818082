"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHardwareInfo = exports.validateEvent = exports.validateRating = exports.isRoomIdOrPeerIdChanged = exports.countOfValidConnections = exports.validateConfig = exports.getConnectionData = exports.dumpStream = exports.map2obj = exports.mangleChromeStats = exports.deltaCompression = exports.logPrefix = void 0;
var GPU = require("./detect-gpu");
exports.logPrefix = function (type) {
    if (type === void 0) { type = "info"; }
    return type === "error"
        ? [
            "%cwatchRTC %cERROR",
            "background: " + "gold" + "; color: black; padding: 2px 0.5em; border-radius: 0.5em;",
            "background: " + "red" + "; color: white; padding: 2px 0.5em; border-radius: 0.5em;",
        ]
        : ["%cwatchRTC", "background: " + "gold" + "; color: black; padding: 2px 0.5em; border-radius: 0.5em;"];
};
// apply a delta compression to the stats report. Reduces size by ~90%.
// To reduce further, report keys could be compressed.
// Muly: maybe can improve using https://github.com/AsyncBanana/microdiff?utm_source=tldrnewsletter
exports.deltaCompression = function (oldStats, newStats) {
    oldStats = oldStats || {};
    newStats = newStats || {};
    newStats = JSON.parse(JSON.stringify(newStats));
    Object.keys(newStats).forEach(function (id) {
        var report = newStats[id];
        delete report.id;
        if (!oldStats[id]) {
            return;
        }
        Object.keys(report).forEach(function (name) {
            if (report[name] === oldStats[id][name]) {
                delete newStats[id][name];
            }
            if (Object.keys(report).length === 0) {
                delete newStats[id];
            }
            else if (Object.keys(report).length === 1 && report.timestamp) {
                delete newStats[id];
            }
        });
    });
    var timestamp = -Infinity;
    Object.keys(newStats).forEach(function (id) {
        var report = newStats[id];
        if (report.timestamp > timestamp) {
            timestamp = report.timestamp;
        }
    });
    Object.keys(newStats).forEach(function (id) {
        var report = newStats[id];
        if (report.timestamp === timestamp) {
            report.timestamp = 0;
        }
    });
    newStats.timestamp = timestamp;
    return newStats;
};
exports.mangleChromeStats = function (pc, response) {
    var standardReport = {};
    var reports = response.result();
    reports.forEach(function (report) {
        var standardStats = {
            id: report.id,
            timestamp: report.timestamp.getTime(),
            type: report.type,
        };
        report.names().forEach(function (name) {
            standardStats[name] = report.stat(name);
        });
        standardReport[standardStats.id] = standardStats;
    });
    return standardReport;
};
// transforms a maplike to an object. Mostly for getStats +
// JSON.parse(JSON.stringify())
exports.map2obj = function (m) {
    if (!m.entries) {
        return m;
    }
    var o = {};
    m.forEach(function (v, k) {
        o[k] = v;
    });
    return o;
};
exports.dumpStream = function (stream) {
    return {
        id: stream.id,
        tracks: stream.getTracks().map(function (track) {
            return {
                id: track.id,
                kind: track.kind,
                label: track.label,
                enabled: track.enabled,
                muted: track.muted,
                readyState: track.readyState,
            };
        }),
    };
};
exports.getConnectionData = function (type, key, overriddenWsUrl) {
    var localUrl = (type == "ws" ? "ws" : "http") + "://localhost:9101";
    var stagingUrl = (type == "ws" ? "wss" : "https") + "://watchrtc-staging2.testrtc.com";
    var productionUrl = (type == "ws" ? "wss" : "https") + "://watchrtc.testrtc.com";
    var splitted = key.split(":");
    if (key.indexOf("local") !== -1) {
        return {
            url: overriddenWsUrl || localUrl,
            key: splitted[1],
        };
    }
    else if (key.indexOf("staging") !== -1) {
        return {
            url: overriddenWsUrl || stagingUrl,
            key: splitted[1],
        };
    }
    else if (key.indexOf("production") !== -1) {
        return {
            url: overriddenWsUrl || productionUrl,
            key: splitted[1],
        };
    }
    else {
        return {
            url: overriddenWsUrl || productionUrl,
            key: splitted[0],
        };
    }
};
exports.validateConfig = function (config) {
    if (!(config === null || config === void 0 ? void 0 : config.rtcApiKey)) {
        if (config === null || config === void 0 ? void 0 : config.debug) {
            console.info.apply(console, __spreadArrays(exports.logPrefix("error"), ["config.rtcApiKey or config.rtcToken need to be provided."]));
            return false;
        }
    }
    if (!(config === null || config === void 0 ? void 0 : config.rtcRoomId) || !(config === null || config === void 0 ? void 0 : config.rtcPeerId)) {
        if (config === null || config === void 0 ? void 0 : config.debug) {
            console.info.apply(console, __spreadArrays(exports.logPrefix("info"), ["rtcRoomId or rtcPeerId is empty."]));
        }
        return false;
    }
    return true;
};
exports.countOfValidConnections = function (openChannels) {
    return Object.keys(openChannels).filter(function (id) { return openChannels[id].validConnection; }).length;
};
exports.isRoomIdOrPeerIdChanged = function (watchrtcIdentifiers, watchrtcConfig) {
    var changed = false;
    if (watchrtcConfig.rtcRoomId &&
        watchrtcIdentifiers.rtcRoomId &&
        watchrtcIdentifiers.rtcRoomId !== watchrtcConfig.rtcRoomId) {
        changed = true;
    }
    if (watchrtcConfig.rtcPeerId &&
        watchrtcIdentifiers.rtcPeerId &&
        watchrtcIdentifiers.rtcPeerId !== watchrtcConfig.rtcPeerId) {
        changed = true;
    }
    if (changed && watchrtcConfig.debug) {
        console.log.apply(console, __spreadArrays(exports.logPrefix("info"), ["maybeOpenWebsocketConnection. rtcRoomId or rtcPeerId has been changed", {
                old: {
                    rtcRoomId: watchrtcIdentifiers.rtcRoomId,
                    rtcPeerId: watchrtcIdentifiers.rtcPeerId,
                },
                new: {
                    rtcRoomId: watchrtcConfig.rtcRoomId,
                    rtcPeerId: watchrtcConfig.rtcPeerId,
                },
            }]));
    }
    return changed;
};
exports.validateRating = function (rating) {
    if (!rating) {
        console.info.apply(console, __spreadArrays(exports.logPrefix("error"), ["rating parameter is required"]));
        return false;
    }
    if (typeof rating !== "number" || rating < 1 || rating > 5) {
        console.info.apply(console, __spreadArrays(exports.logPrefix("error"), ["rating parameter should be number from 1 to 5"]));
        return false;
    }
    return true;
};
exports.validateEvent = function (event) {
    if (!event) {
        console.info.apply(console, __spreadArrays(exports.logPrefix("error"), ["event parameter is required"]));
        return false;
    }
    if (event.type !== "global" && event.type !== "local" && event.type !== "log") {
        console.info.apply(console, __spreadArrays(exports.logPrefix("error"), ["event.type should be either \"global\" or \"local\" or \"log\""]));
        return false;
    }
    if (event.parameters && typeof event.parameters !== "object") {
        console.info.apply(console, __spreadArrays(exports.logPrefix("error"), ["event.parameters should be JSON object\""]));
        return false;
    }
    return true;
};
exports.getHardwareInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
    function number_format(num) {
        return (Math.round(num * 100) / 100).toFixed(2);
    }
    var loadBenchmarks, gpuInfo, result, memory;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                loadBenchmarks = function (fileName) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, new Promise(function (resolve) {
                                    try {
                                        var data = require("./gpu-benchmarks/" + fileName);
                                        data.shift();
                                        return resolve(data);
                                    }
                                    catch (err) {
                                        console.error("Error. getHardwareInfo. loadBenchmarks", { err: err.stack, fileName: fileName });
                                        resolve([]);
                                    }
                                })];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); };
                return [4 /*yield*/, GPU.getGPUTier({
                        override: { loadBenchmarks: loadBenchmarks },
                    })];
            case 1:
                gpuInfo = _a.sent();
                result = __assign(__assign({}, gpuInfo), { cores_count: window.navigator.hardwareConcurrency });
                memory = window.performance["memory"];
                if (memory) {
                    result.jsHeapSizeLimit = number_format(memory.jsHeapSizeLimit / 1048576);
                    result.totalJSHeapSize = number_format(memory.totalJSHeapSize / 1048576);
                    result.usedJSHeapSize = number_format(memory.usedJSHeapSize / 1048576);
                }
                return [2 /*return*/, result];
        }
    });
}); };
