"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapStream = exports.addEvent = exports.enableDataCollection = exports.disableDataCollection = exports.addKeys = exports.setUserRating = exports.setConfig = exports.initSDK = void 0;
var watchrtcsocket_1 = require("./watchrtcsocket");
var watchrtchttp_1 = require("./watchrtchttp");
var utils_1 = require("./utils");
var version_1 = require("./version");
var standardGetstats = true;
var isFirefox = !!window.mozRTCPeerConnection;
var isEdge = !!window.RTCIceGatherer;
var isSafari = !isFirefox && window.RTCPeerConnection && !window.navigator.webkitGetUserMedia;
// Data structure for RTCPeerConnection related stuff we need
var openChannels = {};
var watchrtcIdentifiers = {
    rtcRoomId: undefined,
    rtcPeerId: undefined,
    projectId: undefined,
};
var watchrtcConfig = null;
var http = null;
var socket = null;
var trace;
var lastConnectionOpen = 0; // so we know when was the last active connection seen
var getStatsInterval;
var tryingToConnectSocket = false;
var hardwareInfo;
var maybeOpenWebsocketConnection = function (forceRecreate) {
    var _a;
    if (forceRecreate === void 0) { forceRecreate = false; }
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    if (opened) {
        var roomIdOrPeerIdChanged = utils_1.isRoomIdOrPeerIdChanged(watchrtcIdentifiers, watchrtcConfig);
        if (roomIdOrPeerIdChanged && forceRecreate) {
            if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.debug) {
                console.log.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["maybeOpenWebsocketConnection. Closing WS connection"]));
            }
            socket === null || socket === void 0 ? void 0 : socket.close();
        }
        else {
            if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.debug) {
                console.log.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["maybeOpenWebsocketConnection. WS connection already opened"]));
            }
            return;
        }
    }
    var connectionCount = utils_1.countOfValidConnections(openChannels);
    if (connectionCount > 0 && !tryingToConnectSocket) {
        var canConnect = utils_1.validateConfig(watchrtcConfig);
        var id_1 = Object.keys(openChannels)[connectionCount - 1]; // not very critical, but for consistency with trace
        if (canConnect) {
            if (watchrtcConfig.keys) {
                Object.keys(watchrtcConfig.keys || {}).forEach(function (k) {
                    if (typeof watchrtcConfig.keys[k] === "string") {
                        watchrtcConfig.keys[k] = [watchrtcConfig.keys[k]];
                    }
                });
            }
            var useToken = !!watchrtcConfig.rtcToken;
            var wsConnectionData = utils_1.getConnectionData("ws", useToken ? watchrtcConfig.rtcToken : watchrtcConfig.rtcApiKey, watchrtcConfig.proxyUrl || watchrtcConfig.wsUrl);
            tryingToConnectSocket = true;
            lastConnectionOpen = Date.now();
            socket === null || socket === void 0 ? void 0 : socket.connect(wsConnectionData.url + "?" + (useToken ? "token" : "apiKey") + "=" + wsConnectionData.key + "&timestamp=" + Date.now(), function (data) {
                watchrtcIdentifiers.rtcRoomId = watchrtcConfig.rtcRoomId;
                watchrtcIdentifiers.rtcPeerId = watchrtcConfig.rtcPeerId;
                watchrtcIdentifiers.projectId = data.projectId;
                tryingToConnectSocket = false;
                console.info.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["Connection established. watchRTCConnectionId: " + data.connectionId + " sdkVersion:" + version_1.default]));
                trace("watchrtc", id_1, __assign(__assign(__assign({}, watchrtcConfig), data), { sdkVersion: version_1.default }));
                if (hardwareInfo) {
                    trace("hardware", null, hardwareInfo);
                }
                if (!isEdge) {
                    window.clearInterval(getStatsInterval);
                    getStatsInterval = window.setInterval(function () {
                        if (utils_1.countOfValidConnections(openChannels) === 0) {
                            // if we don't have any connection for 20 sec we can close the socket
                            if (lastConnectionOpen && lastConnectionOpen + 20000 < Date.now()) {
                                window.clearInterval(getStatsInterval);
                                socket === null || socket === void 0 ? void 0 : socket.close();
                                console.info.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["Last connection closed. watchRTCConnectionId: " + data.connectionId + " sdkVersion: " + version_1.default]));
                            }
                        }
                        else {
                            lastConnectionOpen = Date.now();
                            Object.values(openChannels).forEach(function (pcInfo) {
                                if (pcInfo.pc.signalingState !== "closed") {
                                    getStats(pcInfo);
                                }
                            });
                        }
                    }, data.interval);
                }
            }, function () {
                tryingToConnectSocket = false;
                lastConnectionOpen = 0;
            });
        }
        else {
            tryingToConnectSocket = false;
        }
    }
};
var getStats = function (pcInfo) {
    if (pcInfo) {
        var id_2 = pcInfo.id, pc_1 = pcInfo.pc, prev_1 = pcInfo.prev;
        if (standardGetstats || isFirefox || isSafari) {
            pc_1.getStats(null).then(function (res) {
                var now = {};
                if (isFirefox) {
                    res.forEach(function (report) {
                        now[report.type + "_" + report.id] = report;
                    });
                }
                else {
                    now = utils_1.map2obj(res);
                }
                var base = JSON.parse(JSON.stringify(now)); // our new prev
                var data = utils_1.deltaCompression(prev_1, now);
                if ((data === null || data === void 0 ? void 0 : data.timestamp) !== null && (data === null || data === void 0 ? void 0 : data.timestamp) !== -Infinity) {
                    trace("getstats", id_2, data);
                }
                pcInfo.prev = base;
            });
        }
        else {
            pc_1.getStats(function (res) {
                var now = utils_1.mangleChromeStats(pc_1, res);
                var base = JSON.parse(JSON.stringify(now)); // our new prev
                var data = utils_1.deltaCompression(prev_1, now);
                if ((data === null || data === void 0 ? void 0 : data.timestamp) !== null && (data === null || data === void 0 ? void 0 : data.timestamp) !== -Infinity) {
                    trace("getstats", id_2, data);
                }
                pcInfo.prev = base;
            });
        }
    }
};
var getHardware = function () {
    setTimeout(function () {
        // if get hardware take more than 50 ms then do not save it
        var getHardwareStartTime = Date.now();
        utils_1.getHardwareInfo()
            .then(function (hwInfo) {
            var getHardwareTime = Date.now() - getHardwareStartTime;
            if (getHardwareTime <= 50000) {
                hardwareInfo = hwInfo;
            }
        })
            .catch(function (err) {
            console.error("Error. Get hardware info: " + err.message);
        });
    }, 0);
};
/**
 * Initialize SDK.
 * @param watchrtc
 * @param prefixesToWrap
 * @param services
 */
exports.initSDK = function (watchrtc, prefixesToWrap, services) {
    var initialized = window.watchRTCInitialized;
    if (initialized) {
        return;
    }
    else {
        window.watchRTCInitialized = true;
        getHardware();
    }
    var peerconnectioncounter = 0;
    socket = (services === null || services === void 0 ? void 0 : services.socketService) || new watchrtcsocket_1.default({ debug: watchrtc === null || watchrtc === void 0 ? void 0 : watchrtc.debug });
    http = (services === null || services === void 0 ? void 0 : services.httpService) || new watchrtchttp_1.default({ debug: watchrtc === null || watchrtc === void 0 ? void 0 : watchrtc.debug });
    watchrtcConfig = watchrtc;
    trace = socket.trace;
    prefixesToWrap.forEach(function (prefix) {
        if (!window[prefix + "RTCPeerConnection"]) {
            return;
        }
        if (prefix === "webkit" && isEdge) {
            // dont wrap webkitRTCPeerconnection in Edge.
            return;
        }
        var origPeerConnection = window[prefix + "RTCPeerConnection"];
        var peerconnection = function (config, constraints) {
            if (config === null || config === void 0 ? void 0 : config.watchrtc) {
                watchrtcConfig = __assign(__assign({}, watchrtcConfig), config.watchrtc);
            }
            if (watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.debug) {
                console.info.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["new RTCPeerConnection called.", {
                        config: config,
                        constraints: constraints,
                    }]));
            }
            var pc = new origPeerConnection(config, constraints);
            var id = "PC_" + peerconnectioncounter++;
            pc.__rtcStatsId = id;
            openChannels[id] = {
                id: id,
                pc: pc,
                validConnection: false,
            };
            if (!config) {
                config = { nullConfig: true };
            }
            config = JSON.parse(JSON.stringify(config)); // deepcopy
            // don't log credentials
            ((config && config.iceServers) || []).forEach(function (server) {
                delete server.credential;
            });
            if (config === null || config === void 0 ? void 0 : config.watchrtc) {
                delete config.watchrtc;
            }
            if (isFirefox) {
                config.browserType = "moz";
            }
            else if (isEdge) {
                config.browserType = "edge";
            }
            else {
                config.browserType = "webkit";
            }
            trace("create", id, config);
            // TODO: do we want to log constraints here? They are chrome-proprietary.
            // http://stackoverflow.com/questions/31003928/what-do-each-of-these-experimental-goog-rtcpeerconnectionconstraints-do
            if (constraints) {
                trace("constraints", id, constraints);
            }
            // wrap RTCRtpSender.setParameters
            if ("RTCRtpSender" in window && "setParameters" in window.RTCRtpSender.prototype) {
                var origRTCRtpSender = window.RTCRtpSender;
                var nativeMethod_1 = origRTCRtpSender.prototype.setParameters;
                origRTCRtpSender.prototype.setParameters = function () {
                    trace("setParameters", id, arguments);
                    return nativeMethod_1.apply(this, arguments);
                };
            }
            pc.addEventListener("icecandidate", function (e) {
                trace("onicecandidate", id, e.candidate);
            });
            pc.addEventListener("icecandidateerror", function (e) {
                trace("onicecandidateerror", id, e);
            });
            pc.addEventListener("addstream", function (e) {
                trace("onaddstream", id, e.stream.id +
                    " " +
                    e.stream.getTracks().map(function (t) {
                        return t.kind + ":" + t.id;
                    }));
            });
            pc.addEventListener("track", function (e) {
                trace("ontrack", id, e.track.kind +
                    ":" +
                    e.track.id +
                    " " +
                    e.streams.map(function (stream) {
                        return "stream:" + stream.id;
                    }));
            });
            pc.addEventListener("removestream", function (e) {
                trace("onremovestream", id, e.stream.id +
                    " " +
                    e.stream.getTracks().map(function (t) {
                        return t.kind + ":" + t.id;
                    }));
            });
            pc.addEventListener("signalingstatechange", function () {
                if (openChannels[id] && !openChannels[id].validConnection) {
                    openChannels[id].validConnection = true;
                    setTimeout(function () {
                        maybeOpenWebsocketConnection(true);
                    }, 5000);
                }
                trace("onsignalingstatechange", id, pc.signalingState);
            });
            pc.addEventListener("iceconnectionstatechange", function () {
                trace("oniceconnectionstatechange", id, pc.iceConnectionState);
            });
            pc.addEventListener("icegatheringstatechange", function () {
                trace("onicegatheringstatechange", id, pc.iceGatheringState);
            });
            pc.addEventListener("connectionstatechange", function () {
                trace("onconnectionstatechange", id, pc.connectionState);
            });
            pc.addEventListener("negotiationneeded", function () {
                trace("onnegotiationneeded", id, undefined);
            });
            pc.addEventListener("datachannel", function (event) {
                trace("ondatachannel", id, [event.channel.id, event.channel.label]);
            });
            if (!isEdge) {
                pc.addEventListener("iceconnectionstatechange", function () {
                    if (pc.iceConnectionState === "connected") {
                        getStats(openChannels[id]);
                    }
                });
            }
            return pc;
        };
        ["createDataChannel"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    trace(method, this.__rtcStatsId, arguments);
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["close"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    trace(method, this.__rtcStatsId, arguments);
                    delete openChannels[this.__rtcStatsId];
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["addStream", "removeStream"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var stream = arguments[0];
                    var streamInfo = stream
                        .getTracks()
                        .map(function (t) {
                        return t.kind + ":" + t.id;
                    })
                        .join(",");
                    trace(method, this.__rtcStatsId, stream.id + " " + streamInfo);
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["addTrack"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var track = arguments[0];
                    var streams = [].slice.call(arguments, 1);
                    trace(method, this.__rtcStatsId, track.kind +
                        ":" +
                        track.id +
                        " " +
                        (streams
                            .map(function (s) {
                            return "stream:" + s.id;
                        })
                            .join(";") || "-"));
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["removeTrack"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var track = arguments[0].track;
                    trace(method, this.__rtcStatsId, track ? track.kind + ":" + track.id : "null");
                    return nativeMethod.apply(this, arguments);
                };
            }
        });
        ["createOffer", "createAnswer"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var rtcStatsId = this.__rtcStatsId;
                    var args = arguments;
                    var opts;
                    if (arguments.length === 1 && typeof arguments[0] === "object") {
                        opts = arguments[0];
                    }
                    else if (arguments.length === 3 && typeof arguments[2] === "object") {
                        opts = arguments[2];
                    }
                    trace(method, this.__rtcStatsId, opts);
                    return nativeMethod.apply(this, opts ? [opts] : undefined).then(function (description) {
                        trace(method + "OnSuccess", rtcStatsId, description);
                        if (args.length > 0 && typeof args[0] === "function") {
                            args[0].apply(null, [description]);
                            return undefined;
                        }
                        return description;
                    }, function (err) {
                        trace(method + "OnFailure", rtcStatsId, err.toString());
                        if (args.length > 1 && typeof args[1] === "function") {
                            args[1].apply(null, [err]);
                            return;
                        }
                        throw err;
                    });
                };
            }
        });
        ["setLocalDescription", "setRemoteDescription", "addIceCandidate"].forEach(function (method) {
            var nativeMethod = origPeerConnection.prototype[method];
            if (nativeMethod) {
                origPeerConnection.prototype[method] = function () {
                    var rtcStatsId = this.__rtcStatsId;
                    var args = arguments;
                    var _this = this;
                    // because of setLocalDescription(null) sometimes we don't have SDP value
                    // fippo suggested: Access pc.localDescription.sdp in the successcallback
                    var needToHandleSDPInSuccessCallback = method === "setLocalDescription" && (!args[0] || (args[0] && !args[0].sdp));
                    trace(method, this.__rtcStatsId, needToHandleSDPInSuccessCallback ? { parameterless: true } : args[0]);
                    return nativeMethod.apply(this, [args[0]]).then(function () {
                        trace(method + "OnSuccess", rtcStatsId, needToHandleSDPInSuccessCallback ? _this === null || _this === void 0 ? void 0 : _this.localDescription : undefined);
                        if (args.length >= 2 && typeof args[1] === "function") {
                            args[1].apply(null, []);
                            return undefined;
                        }
                        return undefined;
                    }, function (err) {
                        trace(method + "OnFailure", rtcStatsId, err.toString());
                        if (args.length >= 3 && typeof args[2] === "function") {
                            args[2].apply(null, [err]);
                            return undefined;
                        }
                        throw err;
                    });
                };
            }
        });
        // wrap static methods. Currently just generateCertificate.
        if (origPeerConnection.generateCertificate) {
            Object.defineProperty(peerconnection, "generateCertificate", {
                get: function () {
                    return arguments.length
                        ? origPeerConnection.generateCertificate.apply(null, arguments)
                        : origPeerConnection.generateCertificate;
                },
            });
        }
        window[prefix + "RTCPeerConnection"] = peerconnection;
        window[prefix + "RTCPeerConnection"].prototype = origPeerConnection.prototype;
    });
    // getUserMedia wrappers
    prefixesToWrap.forEach(function (prefix) {
        var name = prefix + (prefix.length ? "GetUserMedia" : "getUserMedia");
        if (!navigator[name]) {
            return;
        }
        var origGetUserMedia = navigator[name].bind(navigator);
        var gum = function () {
            trace("getUserMedia", null, arguments[0]);
            var cb = arguments[1];
            var eb = arguments[2];
            origGetUserMedia(arguments[0], function (stream) {
                // we log the stream id, track ids and tracks readystate since that is ended GUM fails
                // to acquire the cam (in chrome)
                trace("getUserMediaOnSuccess", null, utils_1.dumpStream(stream));
                if (cb) {
                    cb(stream);
                }
            }, function (err) {
                trace("getUserMediaOnFailure", null, err.name);
                httpReportError();
                if (eb) {
                    eb(err);
                }
            });
        };
        navigator[name] = gum.bind(navigator);
    });
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        var origGetUserMedia_1 = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);
        var gum = function () {
            trace("navigator.mediaDevices.getUserMedia", null, arguments[0]);
            return origGetUserMedia_1.apply(navigator.mediaDevices, arguments).then(function (stream) {
                trace("navigator.mediaDevices.getUserMediaOnSuccess", null, utils_1.dumpStream(stream));
                return stream;
            }, function (err) {
                trace("navigator.mediaDevices.getUserMediaOnFailure", null, err.name);
                httpReportError();
                return Promise.reject(err);
            });
        };
        navigator.mediaDevices.getUserMedia = gum.bind(navigator.mediaDevices);
    }
    // getDisplayMedia
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
        var origGetDisplayMedia_1 = navigator.mediaDevices.getDisplayMedia.bind(navigator.mediaDevices);
        var gdm = function () {
            trace("navigator.mediaDevices.getDisplayMedia", null, arguments[0]);
            return origGetDisplayMedia_1.apply(navigator.mediaDevices, arguments).then(function (stream) {
                trace("navigator.mediaDevices.getDisplayMediaOnSuccess", null, utils_1.dumpStream(stream));
                return stream;
            }, function (err) {
                trace("navigator.mediaDevices.getDisplayMediaOnFailure", null, err.name);
                httpReportError();
                return Promise.reject(err);
            });
        };
        navigator.mediaDevices.getDisplayMedia = gdm.bind(navigator.mediaDevices);
    }
};
exports.setConfig = function (newWatchrtcConfig) {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["SDK is not initialized. Use 'init' function fisrt."]));
        return;
    }
    // if debug mode changed
    // change it in socket service too
    if (newWatchrtcConfig.debug !== undefined && newWatchrtcConfig.debug !== watchrtcConfig.debug) {
        socket === null || socket === void 0 ? void 0 : socket.toggleDebug(newWatchrtcConfig.debug);
    }
    watchrtcConfig = __assign(__assign({}, watchrtcConfig), newWatchrtcConfig);
    maybeOpenWebsocketConnection();
};
/**
 * Set user rating and/or comment for peer session
 * @param rating number from 1 to 5
 * @param comment string
 */
exports.setUserRating = function (
/** 1 | 2 | 3 | 4 | 5 */
rating, ratingComment) {
    var _a;
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["SDK is not initialized. Use 'init' function fisrt."]));
        return;
    }
    var isValidRating = utils_1.validateRating(rating);
    if (!isValidRating) {
        return;
    }
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    var data = ["userRating", null, { rating: rating, ratingComment: ratingComment }];
    if (opened) {
        trace.apply(void 0, data);
    }
    else {
        httpTrace.apply(void 0, data);
    }
};
/**
 * Add keys for peer session
 * @param keys
 */
exports.addKeys = function (
/** { "key1": "value1", "key2": ["value2_1", "value2_2"]} */
keys) {
    var _a;
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["SDK is not initialized. Use 'init' function fisrt."]));
        return;
    }
    Object.keys(keys || {}).forEach(function (k) {
        if (typeof keys[k] === "string") {
            keys[k] = [keys[k]];
        }
    });
    var data = ["keys", null, keys];
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    var wasConnected = socket === null || socket === void 0 ? void 0 : socket.wasConnected;
    if (opened) {
        trace.apply(void 0, data);
    }
    else if (!wasConnected) {
        trace.apply(void 0, data);
    }
    else {
        httpTrace.apply(void 0, data);
    }
};
exports.disableDataCollection = function () {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["SDK is not initialized. Use 'init' function fisrt."]));
        return;
    }
    socket === null || socket === void 0 ? void 0 : socket.disableDataCollection();
};
exports.enableDataCollection = function () {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["SDK is not initialized. Use 'init' function fisrt."]));
        return;
    }
    socket === null || socket === void 0 ? void 0 : socket.enableDataCollection();
};
exports.addEvent = function (event) {
    var _a;
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["addEvent error. SDK is not initialized. Use 'init' function fisrt."]));
        return;
    }
    var opened = ((_a = socket === null || socket === void 0 ? void 0 : socket.connection) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    if (!opened) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["addEvent error. Connection has been closed."]));
        return;
    }
    var isEventValid = utils_1.validateEvent(event);
    if (!isEventValid) {
        return;
    }
    var data = ["event", null, event];
    trace.apply(void 0, data);
};
exports.mapStream = function (id, name) {
    var initialized = window.watchRTCInitialized;
    if (!initialized) {
        console.info.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["mapStream error. SDK is not initialized. Use 'init' function fisrt."]));
        return;
    }
    var isDataValid = !!id && !!name;
    if (!isDataValid) {
        return;
    }
    var data = ["mapStream", null, { id: id, name: name }];
    trace.apply(void 0, data);
};
var httpTrace = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (!watchrtcIdentifiers.rtcRoomId || !watchrtcIdentifiers.rtcPeerId || !watchrtcIdentifiers.projectId) {
        console.log.apply(console, __spreadArrays(utils_1.logPrefix("info"), ["Cannot do http trace before connection established"]));
        return;
    }
    var httpConnectionData = utils_1.getConnectionData("http", watchrtcConfig.rtcApiKey);
    http === null || http === void 0 ? void 0 : http.trace.apply(http, __spreadArrays([httpConnectionData.url + "/trace", watchrtcIdentifiers.projectId,
        watchrtcIdentifiers.rtcRoomId,
        watchrtcIdentifiers.rtcPeerId], data));
};
var httpReportError = function () {
    var apiKey = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcApiKey;
    var rtcRoomId = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcRoomId;
    var rtcPeerId = watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcPeerId;
    if (!apiKey || !rtcRoomId || !rtcPeerId) {
        console.log.apply(console, __spreadArrays(utils_1.logPrefix("error"), ["Cannot report an error. Please provide apiKey, rtcRoomId and rtcPeerId "]));
    }
    var httpConnectionData = utils_1.getConnectionData("http", watchrtcConfig === null || watchrtcConfig === void 0 ? void 0 : watchrtcConfig.rtcApiKey);
    var data = (socket === null || socket === void 0 ? void 0 : socket.buffer) || [];
    http === null || http === void 0 ? void 0 : http.trace(httpConnectionData.url + "/error", httpConnectionData.key, rtcRoomId, rtcPeerId, data);
};
// (window as any).setUserRating = setUserRating;
// (window as any).addTags = addTags;
// (window as any).addKeys = addKeys;
// (window as any).init = initSDK;
// (window as any).addEvent = addEvent;
